.section {
  width: 100%;
  max-width: 1000px;
  padding: 0 1rem;
  margin-bottom: 1.5rem;

  &.table {
    display: flex;
    flex-direction: row;
  }

  p {
    width: 100%;
    font-size: 1.75rem;
    line-height: 1.5;
  }

  .list {
    display: inline-flex;
    flex-direction: column;
    font-size: 1.25rem;
    gap: .5rem;
    list-style-type: none;

    li {
      line-height: 1.5;
    }
  }
}

@media (width <= 540px) {
  .section {
    p {
      font-size: 1.75rem;
    }
  }
}

.socialLinks {
  display: flex;
  gap: 1rem;
  list-style: none;
  padding: 0;
  margin: 2rem 0 0;
}

.socialLink {
  color: inherit;
  text-decoration: none;
  transition: opacity 0.2s ease;
  
  &:hover {
    opacity: 0.7;
  }
}
